<template>
    <div class="container">

        <div class="auth-container" v-if="user && readyToShow">
            <div class="card">
                <div class="card-body">
                    <div class="card-text  d-flex align-items-center">
                        <span class="me-3">
                            <span class="text-break">{{ user.name }}</span>,
                            you have been invited to join
                            <span class="text-break">{{ invitedToAccount.name }}</span>.
                        </span>
                        <button @click="acceptInvitation" class="btn btn-success ms-auto text-nowrap">
                            <i class="fa fa-check-circle"></i> Accept Invitation
                        </button>
                    </div>

                    <hr v-if="networkActivity || errorMessage">

                    <div class="alert alert-blue" v-if="networkActivity">
                        {{ networkActivity }}
                    </div>

                    <div class="alert alert-danger" v-if="errorMessage">
                        {{ errorMessage }}
                    </div>
                </div>
            </div>
        </div>

        <div class="card" v-if="!user && !isCreating && !isLoggingIn">
            <div class="card-body text-center">

                <button @click="isLoggingIn = true;" class="btn btn-primary">
                    Log in to My Account
                </button>
                <br/>
                <br/>
                <button @click="isCreating = true;" class="btn btn-secondary">
                    Create an account
                </button>
            </div>
        </div>

        <login v-if="isLoggingIn" :iToken="inviteToken" @cancel="isLoggingIn = false"/>
        <sign-up v-if="isCreating" :iToken="inviteToken" @cancel="isCreating = false"/>

    </div>
</template>

<script>

import BaseAuth from './Base'
import {mapGetters} from 'vuex';

import Login from './Login'
import SignUp from './SignUp'

export default {
    mixins: [BaseAuth],
    props: ['iToken'],
    components: {
        Login, SignUp
    },
    data() {
        return {
            isCreating: false,
            isLoggingIn: false,
            invitation: false,
            invitedToAccount: false,
            invitedBy: false,
            noInvitor: false
        }
    },
    computed: {
        ...mapGetters({
            user: 'currentUser',
            topic: 'currentTopic',
            accounts: 'allAccounts',
            currentAccountOverview: 'currentAccountOverview',
        }),
        readyToShow() {
            return this.invitedToAccount && (this.invitedBy || this.noInvitor)
        }
    },
    watch: {
        topic() {
            console.log("Topic was set....")
        },
        user() {
            if (this.user) {
                this.isLoggingIn = false;
                this.isCreating = false;
                this.fetchInvitation()
            }
        }
    },
    mounted() {
        this.inviteToken = this.iToken

        if (this.user) {
            this.fetchInvitation()
        }
    },
    methods: {
        fetchInvitation() {
            this.networkActivity = 'Fetching invite...';
            this.$api.get('/invitations/' + this.inviteToken)
                .then(response => {

                    this.invitation = response.data;
                    this.networkActivity = false;
                    this.fetchAccount()
                    this.fetchInvitor()
                })
        },
        fetchAccount() {
            this.$api.get(this.invitation.account)
                .then(response => {
                    this.invitedToAccount = response.data
                })
        },
        fetchInvitor() {
            if (this.invitation.invitor) {
                this.$api.get(this.invitation.invitor)
                    .then(response => {
                        this.invitedBy = response.data
                    })
            } else {
                this.noInvitor = true;
            }
        },
        async acceptInvitation() {
            this.networkActivity = "Accepting invitation..."
            let token = (this.$route.query.data);
            var data = {}
            if (token) {
                data.token = token
            } else {
                data.invitation = this.inviteToken
            }
            await this.$api.post('/accounts/' + this.invitedToAccount.id + '/users', data)
                .then(async() => {
                    window.Events.$emit('ReloadUserAccounts');
                    console.log(`* TeamInvite acceptInvitation about to dispatch initialize with user:`, this.user)
                    await this.$store.dispatch('initialize');
                    if(this.currentAccountOverview?.topics.length === 0 && this.currentAccountOverview?.collection.length === 0) {
                        await this.$router.push('/welcome');
                    } else {
                        await this.$router.push('/');
                    }
                })
                .catch(e => {
                    this.networkActivity = false;
                    if (e.response) {
                        switch (e.response.status) {
                            case 400:
                                this.errorMessage = e.response.data.description
                                break;
                        }
                    } else {
                        this.errorMessage = 'Something went wrong'
                    }
                });
        }
    }
}
</script>

<style scoped>
.container {
    display: flex;
    height: 80vh;
    align-content: center;
    justify-content: center;
    justify-items: center;
    align-items: center;
}

.container .auth-container {
    width: 100%;
}
</style>
